import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AccountV1 } from '../../../_models/account';


@Component({
  selector: 'app-editcustomereditview',
  templateUrl: './editcustomereditview.component.html',
  styleUrls: ['./editcustomereditview.component.scss']
})
export class EditcustomereditviewComponent implements OnInit {

  public channelForm: FormGroup;
  public account: AccountV1;

  // convenience getter for easy access to form fields
  get f() { return this.channelForm.controls; }


  constructor(private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<EditcustomereditviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.account = data.customer;
  }

  ngOnInit() {

    this.channelForm = this.formBuilder.group({
      id: ['', Validators.required],
      companyname: ['', [Validators.required, Validators.maxLength(50)]],
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      firstname: ['', [Validators.required, Validators.maxLength(50)]],
      lastname: ['', [Validators.required, Validators.maxLength(50)]],
      username: ['', [Validators.required, Validators.maxLength(50)]],
      website: ['', Validators.maxLength(250)],
      isadmin: ['', Validators.required],
      isemailverified: ['', Validators.maxLength(500)],
      photourl: ['']
    });

    this.f.id.setValue(this.account.id);
    this.f.companyname.setValue(this.account.companyname);
    this.f.email.setValue(this.account.email);
    this.f.firstname.setValue(this.account.firstname);
    this.f.lastname.setValue(this.account.lastname);
    this.f.username.setValue(this.account.username);
    this.f.website.setValue(this.account.website);
    this.f.isadmin.setValue(this.account.isadmin);
    this.f.isemailverified.setValue(this.account.isemailverified);
    this.f.photourl.setValue(this.account.photourl);
  }



  onSubmit() {
    // stop here if form is invalid
    if (this.channelForm.invalid) {
      return;
    }

    this.dialogRef.close(this.channelForm);
  }

  close() {
    this.dialogRef.close(null);
  }

}
