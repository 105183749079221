import { Role } from './role';

export class User {
    id: string;
    companyname: string;
    email: string;
    firstname: string;
    lastname: string;
    username: string;
    website: string;
    isadmin: boolean;
    isemailverified: boolean;
    role: Role;
    photourl: string;
    token?: string;
}
