import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-linkdevice',
  templateUrl: './linkdevice.component.html',
  styleUrls: ['./linkdevice.component.scss']
})
export class LinkdeviceComponent implements OnInit {

  public channelForm: FormGroup;

  // convenience getter for easy access to form fields
  get f() { return this.channelForm.controls; }

  constructor(private formBuilder: FormBuilder,
              public dialogRef: MatDialogRef<LinkdeviceComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit() {
    this.channelForm = this.formBuilder.group({
      serial: ['', Validators.required],
      code1: ['', Validators.required],
      code2: ['', Validators.required],
      code3: ['', Validators.required],
      code4: ['', Validators.required]
    });
  }

  onSubmit() {
    // stop here if form is invalid
    if (this.channelForm.invalid) {
      return;
    }

    this.dialogRef.close(this.channelForm);
  }

  close() {
    this.dialogRef.close(null);
  }
}
