import { Component, OnInit, Input } from '@angular/core';
import { AppSandbox } from '../../sandboxes/app.sandbox';


@Component({
  selector: 'app-digitbutton',
  templateUrl: './digitbutton.component.html',
  styleUrls: ['./digitbutton.component.scss']
})
export class DigitbuttonComponent implements OnInit {

  private _channelindex = 1;
  private _ledstatus = 0;
  private _serial = '';
  private _title = '';
  private _mode = 0;

  public statusclass = '';

  @Input()
  set serial(serial: string) {
    this._serial = serial || '';
  }
  get serial(): string {
    return this._serial;
  }

  @Input()
  set channelindex(channelindex: number) {
    this._channelindex = channelindex || 1;
    this.calculateStyle();
  }
  get channelindex(): number {
    return this._channelindex;
  }

  @Input()
  set mode(mode: number) {
    this._mode = mode || 1;
    this.calculateStyle();
  }
  get mode(): number {
    return this._mode;
  }

  @Input()
  set title(title: string) {
    this._title = title || '1';
  }
  get title(): string {
    return this._title;
  }

  @Input()
  set ledstatus(ledstatus: number) {
    this._ledstatus = ledstatus;
    this.calculateStyle();
  }
  get ledstatus(): number {
    return this._ledstatus;
  }

  constructor(public sb: AppSandbox) {


  }

  ngOnInit() {
  }

  onClick() {
    this.sb.showChannel(this._serial, this._channelindex);
  }

  private calculateStyle() {
    var result = 'btn btn-block f-h-50 ';

    // see DigitButtonStatus

    switch (this.ledstatus) {
      case -1:
        // Disabled
        result = result + ' btn-outline-dark disabled';
        break;
      case 0:
        // HasCodeAndIsFilled
        if (this.channelindex == 1) {
          result = result + ' btn-primary';
        } else if (this.channelindex == 2) {
          result = result + ' btn-success';
        } else if (this.channelindex == 3) {
          result = result + ' btn-warning';
        } else if (this.channelindex == 4) {
          result = result + ' btn-danger';
        }

        if (this.mode == 2) {
          result = result + ' active';
        }
        break;
      case 1:
        // color
        if (this.channelindex == 1) {
          result = result + ' btn-outline-primary';
        } else if (this.channelindex == 2) {
          result = result + ' btn-outline-success';
        } else if (this.channelindex == 3) {
          result = result + ' btn-outline-warning';
        } else if (this.channelindex == 4) {
          result = result + ' btn-outline-danger';
        }
        break;


      // case 1:
      //   result = result + ' btn-primary disabled';
      //   break;
      case 2:
        // color
        if (this.channelindex == 1) {
          result = result + ' btn-outline-primary status-primary-reserved';
        } else if (this.channelindex == 2) {
          result = result + ' btn-outline-success status-success-reserved';
        } else if (this.channelindex == 3) {
          result = result + ' btn-outline-warning status-warning-reserved';
        } else if (this.channelindex == 4) {
          result = result + ' btn-outline-danger status-danger-reserved';
        }
        break;
      case 3:
        // color
        if (this.channelindex == 1) {
          result = result + ' btn-primary status-primary-reserved';
        } else if (this.channelindex == 2) {
          result = result + ' btn-success status-success-reserved';
        } else if (this.channelindex == 3) {
          result = result + ' btn-warning status-warning-reserved';
        } else if (this.channelindex == 4) {
          result = result + ' btn-danger status-danger-reserved';
        }
        break;
    }

    this.statusclass = result;
  }

}
