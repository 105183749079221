<mat-card>
    <mat-card-title>Edit a account</mat-card-title>
    <mat-card-subtitle>It's easy to edit a existing account!</mat-card-subtitle>
    <mat-card-content>
        <p>please fill in the form below:</p>
        <form [formGroup]="channelForm" (ngSubmit)="onSubmit()">
            <mat-form-field>
                <input type="text" matInput placeholder="Company name" autocomplete="off" formControlName="companyname">
            </mat-form-field><br />
            <mat-form-field>
                <input type="text" matInput placeholder="First Name" autocomplete="off" formControlName="firstname">
            </mat-form-field><br />
            <mat-form-field>
                <input type="text" matInput placeholder="Last Name" autocomplete="off" formControlName="lastname">
            </mat-form-field><br />
            <mat-form-field>
                <input type="text" matInput placeholder="Username" autocomplete="off" formControlName="username">
            </mat-form-field><br />
            <mat-form-field>
                <input type="text" matInput placeholder="Email" autocomplete="off" formControlName="email">
            </mat-form-field><br />
            <mat-form-field>
                <input type="text" matInput placeholder="website" autocomplete="off" formControlName="website">
            </mat-form-field><br />
            <mat-checkbox matInput formControlName="isadmin" labelPosition="after">Is Admin?</mat-checkbox><br />      
            <mat-form-field>
                <input type="text" matInput placeholder="Photo Url" autocomplete="off" formControlName="photourl">
            </mat-form-field><br />

            <div class="button">
                <button type="submit" mat-button>Save</button>
                <a class="button" (click)="close()">Cancel</a>
            </div>
        </form>
    </mat-card-content>
</mat-card>