import { Component, OnInit } from '@angular/core';
import { AppSandbox } from '../../sandboxes/app.sandbox';


@Component({
  selector: 'app-adminmenu',
  templateUrl: './adminmenu.component.html',
  styleUrls: ['./adminmenu.component.scss']
})
export class AdminmenuComponent implements OnInit {

  constructor(public sb: AppSandbox) {
   
  }

  ngOnInit() {
  }

}
