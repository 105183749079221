<div class="col-md-12">

    <button ngbTooltip="Refresh" placement="top" (click)="sb.reloadDeviceEvents()" class="btn btn-md m-1"><i
            class="fa fa-2x fa-refresh"></i>
    </button>
</div>
<div class="col-md-12">
        <table class="table "> <!-- table-responsive -->
                <thead>
                  <tr>
                    <!-- <th scope="col">Date</th> -->
                    <th scope="col">Info</th>                 
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let it of events$ | async">
                    <td>
                        <strong>{{ it.date | date:'short' }} | Channel: {{it.channel}}</strong> <br/>
                        <p>{{it.message}}</p>
                       
                    </td>
                  </tr>                 
                </tbody>
              </table>
</div>