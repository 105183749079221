<div class="row">
    <div class="col-md-12">

        <button ngbTooltip="Refresh" placement="top" (click)="sb.reloadChannels()" class="btn btn-md m-0"><i
                class="fa fa-2x fa-refresh"></i>
        </button>

        <button ngbTooltip="Add Device" placement="top" (click)="sb.linkNewDevice()" class="btn btn-md m-1"><i
                class="fa fa-2x fa-plus"></i>
        </button>
    </div>
</div>
<div class="row">
    <div *ngFor="let it of (devices$|async)" class="col-ld-8 col-md-6">
        <div class="card b-1 hover-shadow mt-2 mb-2">
            <div class="card-header">
                {{it.serial}}
            </div>
            <div class="card-body">
                <div class="row" *ngIf="it.isOnline">
                    <app-digitbutton [serial]="it.serial" [channelindex]="it.ch1.index" [title]="it.ch1.alias"
                        [ledstatus]="it.ch1.ledStatus" [mode]="it.ch1.mode" class="col-3"></app-digitbutton>
                    <app-digitbutton [serial]="it.serial" [channelindex]="it.ch2.index" [title]="it.ch2.alias"
                        [ledstatus]="it.ch2.ledStatus" [mode]="it.ch2.mode" class="col-3"></app-digitbutton>
                    <app-digitbutton [serial]="it.serial" [channelindex]="it.ch3.index" [title]='it.ch3.alias'
                        [ledstatus]="it.ch3.ledStatus" [mode]="it.ch3.mode" class="col-3"></app-digitbutton>
                    <app-digitbutton [serial]="it.serial" [channelindex]="it.ch4.index" [title]='it.ch4.alias'
                        [ledstatus]="it.ch4.ledStatus" [mode]="it.ch4.mode" class="col-3"></app-digitbutton>
                </div>
                <div class="row" *ngIf="!it.isOnline">
                  <span class="m-1" ><i class="fa fa-chain-broken"></i> This device is currently offline!</span>
                </div>
            </div>

        </div>
    </div>

</div>