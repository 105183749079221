<mat-card>
    <mat-card-title>Register</mat-card-title>
    <mat-card-content>


        <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
                <label for="companyname">Company Name</label>
                <input type="text" formControlName="companyname" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.companyname.errors }" />
                <div *ngIf="submitted && f.companyname.errors" class="invalid-feedback">
                    <div *ngIf="f.companyname.errors.required">Company Name is required</div>
                </div>
            </div>
            <div class="form-group">
                <label for="email">Email</label>
                <input type="text" formControlName="email" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">Email is required</div>
                </div>
            </div>
            <div class="form-group">
                <label for="firstname">First Name</label>
                <input type="text" formControlName="firstname" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }" />
                <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
                    <div *ngIf="f.firstname.errors.required">First Name is required</div>
                </div>
            </div>
            <div class="form-group">
                <label for="lastname">Last Name</label>
                <input type="text" formControlName="lastname" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }" />
                <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
                    <div *ngIf="f.lastname.errors.required">Last Name is required</div>
                </div>
            </div>
            <div class="form-group">
                <label for="username">Username</label>
                <input type="text" formControlName="username" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                    <div *ngIf="f.username.errors.required">Username is required</div>
                </div>
            </div>
            <div class="form-group">
                <label for="password">Password</label>
                <input type="password" formControlName="password" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.required">Password is required</div>
                    <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
                </div>
            </div>
            <div class="form-group">
                <label for="website">Website</label>
                <input type="text" formControlName="website" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.website.errors }" />
                <div *ngIf="submitted && f.website.errors" class="invalid-feedback">
                    <div *ngIf="f.website.errors.required">Website is required</div>
                </div>
            </div>
            <div class="form-group">
                <label for="photourl">Photo Url</label>
                <input type="text" formControlName="photourl" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.photourl.errors }" />
                <div *ngIf="submitted && f.photourl.errors" class="invalid-feedback">
                    <div *ngIf="f.photourl.errors.required">Photo Url is required</div>
                </div>
            </div>

            <div class="form-group">
                <button [disabled]="loading" class="btn btn-primary">Register</button>
                <img *ngIf="loading" class="pl-3"
                    src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />

                <a routerLink="/login" class="btn btn-link">Cancel</a>
            </div>
        </form>

    </mat-card-content>
</mat-card>