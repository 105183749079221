import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { AppSandbox } from '../../../sandboxes/app.sandbox';
import { AccountV1 } from '../../../_models/account';


@Component({
  selector: 'app-customersview',
  templateUrl: './customersview.component.html',
  styleUrls: ['./customersview.component.scss']
})
export class CustomersviewComponent implements OnInit, AfterViewInit {

  //https://material.angular.io/components/table/overview

  public displayedColumns: string[] = ['firstname', 'lastname', 'companyname', 'username', 'isadmin', 'details'];
  public dataSource = new MatTableDataSource<AccountV1>([]);

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  public accounts$ = this.sb.accounts$;

  constructor(public sb: AppSandbox) {
    this.sb.reloadAccounts();
  }

  ngOnInit() {
    this.sb.accounts$.subscribe(data => {
      this.dataSource = new MatTableDataSource<AccountV1>(data);
      this.dataSource.paginator = this.paginator;
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  public edit(account: any) {
    this.sb.editCustomer(account);
  }


}
