import { ApiService } from '../api.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DeviceCommandService extends ApiService {

    constructor(httpClient: HttpClient) {
        super(httpClient, 'devicecommand', 'v1', 'devicecommand');
    }

}
