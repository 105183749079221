import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { AppSandbox } from '../../../sandboxes/app.sandbox';



@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  public currentUser$ = this.sb.currentUser$;
  public photourl$ = this.currentUser$.pipe(
map(it=>{
  // if (it===null || it.photourl===null || it.photourl===''){
    return  '../../../../assets/images/generic-person-profile.jpg';
  // }
  // else {
  //   return it.photourl;
  // }
})    
  );

  constructor(private sb: AppSandbox) {

  }

  ngOnInit() {
  }

}
