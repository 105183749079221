import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DeviceService extends ApiService {

    constructor(httpClient: HttpClient) {
        super(httpClient, 'device', 'v1', 'device');
    }

}
