import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './material/material.module';
import { HeaderComponent } from './navigation/header/header.component';
import { SidenavListComponent } from './navigation/sidenav-list/sidenav-list.component';
import { HomeComponent } from './views/home/home.component';
import { LoginComponent } from './views/login/login.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { ReactiveFormsModule } from '@angular/forms';
import { ChannellistitemComponent } from './views/channels/channellistitem/channellistitem.component';
import { ChannellistComponent } from './views/channels/channellist/channellist.component';
import { AppSandbox } from './sandboxes/app.sandbox';
import { ApiModule } from './api/api.module';
import { AccountComponent } from './views/account/account/account.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { FooterComponent } from './navigation/footer/footer.component';
import { EventComponent } from './views/event/event.component';
import { ChanneleditComponent } from './views/channels/channeledit/channeledit.component';
import { AdminmenuComponent } from './views/adminmenu/adminmenu.component';
import { CustomersviewComponent } from './views/adminviews/customersview/customersview.component';
import { LinkdeviceComponent } from './views/linkdevice/linkdevice.component';
import { RegisterComponent } from './views/register/register.component';
import { EditcustomereditviewComponent } from './views/adminviews/editcustomereditview/editcustomereditview.component';
import { DevicelistComponent } from './views/channels/devicelist/devicelist.component';
import { DigitbuttonComponent } from './components/digitbutton/digitbutton.component';
import { ChanneldetailComponent } from './views/channels/channeldetail/channeldetail.component';
import { GuidedTourModule, GuidedTourService } from 'ngx-guided-tour';



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidenavListComponent,
    HomeComponent,
    LoginComponent,
    ChannellistitemComponent,
    ChannellistComponent,
    AccountComponent,
    EventComponent,
    FooterComponent,
    EventComponent,
    ChanneleditComponent,
    AdminmenuComponent,
    CustomersviewComponent,
    CustomersviewComponent,
    LinkdeviceComponent,
    RegisterComponent,
    EditcustomereditviewComponent,
    DevicelistComponent,
    DigitbuttonComponent,
    ChanneldetailComponent
  ],
  entryComponents: [ChanneleditComponent,EditcustomereditviewComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    MaterialModule,
    GuidedTourModule,
    ApiModule.forRoot(),
    MDBBootstrapModule.forRoot()
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    AppSandbox,
    GuidedTourService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
