import { ApiService } from '../api.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class EventService extends ApiService {

    constructor(httpClient: HttpClient) {
        super(httpClient, 'event', 'v1', 'event');
    }

}
