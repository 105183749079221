export class AccountV1 {
    id: string;
    companyname: string;
    email: string;
    firstname: string;
    lastname: string;
    username: string;
    website: string;
    isadmin: false;
    isemailverified: false;
    photourl: string;
}
