<div class="profile-page">
    <div class="profile-content">
        <div class="container">
            <div class="row">
                <div class="col-md-6 ml-auto mr-auto">
                    <div class="profile">
                        <div class="avatar">
                            <img [src]="photourl$ | async" class="img-raised rounded-circle img-fluid">
                        </div>
                        <div class="name">
                            <h3 class="title">{{ (currentUser$|async).firstname  }} {{ (currentUser$|async).lastname }}</h3>
                            <h6 *ngIf="(currentUser$|async).companyname">{{(currentUser$|async).companyname }}</h6>
                            <a href="mailto:{{(currentUser$|async).email}}" class="btn btn-just-icon btn-link btn-dribbble">
                                <mat-icon>email</mat-icon>
                            </a>
                            <a href="{{(currentUser$|async).website}}" class="btn btn-just-icon btn-link btn-dribbble">
                                <mat-icon>language</mat-icon>
                            </a>                           
                        </div>
                    </div>
                </div>
            </div>
            <div class="description text-center">
                <p>Currently there is no more information about you.  This shall change in the future to inform you more about for example your billing information or orders statusses</p>
            </div>
            <!-- <div class="row">
                <div class="col-md-6 ml-auto mr-auto">
                    <div class="profile-tabs">
                        <ul class="nav nav-pills nav-pills-icons justify-content-center" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" href="#studio" role="tab" data-toggle="tab">
                                    <i class="material-icons">camera</i>
                                    Studio
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#works" role="tab" data-toggle="tab">
                                    <i class="material-icons">palette</i>
                                    Work
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#favorite" role="tab" data-toggle="tab">
                                    <i class="material-icons">favorite</i>
                                    Favorite
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div> -->




        </div>
    </div>
</div>