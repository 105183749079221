import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { GuidedTour, GuidedTourService, Orientation, TourStep } from "ngx-guided-tour";
import { AppSandbox } from '../../../sandboxes/app.sandbox';
import { Channel } from '../../../_models/channel';

@Component({
  selector: "app-channeldetail",
  templateUrl: "./channeldetail.component.html",
  styleUrls: ["./channeldetail.component.scss"],
})
export class ChanneldetailComponent implements OnInit {
  public channel: Channel = new Channel();

  constructor(
    public sb: AppSandbox,
    private router: Router,
    private tourService: GuidedTourService
  ) {}

  ngOnInit() {
    this.sb.currentchannel$.subscribe((it) => {
      this.channel = it;
    });
  }

  public goBack() {
    this.router.navigate(["/devicelist"]);
  }

  public startTour() {
    const step1: TourStep = {
      selector: ".myButtonBack",
      content: "Click here to return to the previous page",
      orientation: Orientation.BottomLeft
    };
    const step2: TourStep = {
      selector: ".myButtonWizard",
      content: "Click here to open the wizard and create a new secret",
      orientation: Orientation.TopLeft
    };
    
    const step3: TourStep = {
      selector: ".myButtonChangePassword",
      content: "Click here to change the current secret of this locker.",
      orientation: Orientation.TopLeft
    };
    const step4: TourStep = {
      selector: ".myButtonFill",
      content: "Click here to mark this locker as Filled/Empty.",
      orientation: Orientation.TopRight
    };
    const step5: TourStep = {
      selector: ".myButtonOpen",
      content: "Click here to open this locker without a status change.",
      orientation: Orientation.TopRight
    };
    const tour: GuidedTour = {
      tourId: "demodetail",
      steps: [step1, step2, step3, step4, step5],
    };
    this.tourService.startTour(tour);
  }
}
