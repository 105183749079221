<mat-nav-list>
    <a mat-list-item routerLink="/home" (click)="onSidenavClose()">
        <mat-icon>home</mat-icon> <span class="nav-caption">Home</span>
    </a>

    <a mat-list-item routerLink="/account" (click)="onSidenavClose()">
        <mat-icon>assignment_ind</mat-icon> <span class="nav-caption">Account</span>
    </a> 
    <a mat-list-item routerLink="/devicelist" (click)="onSidenavClose()">
        <mat-icon>album</mat-icon><span class="nav-caption">Devices</span>
    </a>
    <a mat-list-item routerLink="/events" (click)="onSidenavClose()">
        <mat-icon>new_releases</mat-icon><span class="nav-caption">Events</span>
    </a>

    <a *ngIf="isAdmin$|async" mat-list-item routerLink="/admin" (click)="onSidenavClose()">
        <mat-icon>verified_user</mat-icon><span class="nav-caption">Admin</span>
    </a>

    <a mat-list-item (click)="sb.logout()">
        <mat-icon>phonelink_lock</mat-icon><span class="nav-caption">Logout</span>
    </a>


</mat-nav-list>