import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AppSandbox } from '../../sandboxes/app.sandbox';


@Component({
  selector: 'app-sidenav-list',
  templateUrl: './sidenav-list.component.html',
  styleUrls: ['./sidenav-list.component.scss']
})
export class SidenavListComponent implements OnInit {
  @Output() sidenavClose = new EventEmitter();

  public currentUser$ = this.sb.currentUser$;
  public isAdmin$ = this.sb.isAdmin$;

  constructor(public sb: AppSandbox) {

  }

  ngOnInit() {
  }

  public onSidenavClose = () => {
    this.sidenavClose.emit();
  }

}
