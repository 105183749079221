<div class="row">
    <div class="col-md-1">
        <button ngbTooltip="back" placement="top" (click)="goBack()" class="btn btn-md m-0">
            <i class="fa fa-2x fa-arrow-left myButtonBack"></i>
        </button>
    </div>
    <div class="col-md-1">
        <button ngbTooltip="Info" placement="top" (click)="startTour()" class="btn btn-md m-0">
            <i class="fa fa-2x fa-question"></i>
       </button>
    </div>
    <div class="col-md-10">
        <div *ngIf="sb.isReLoading" style="margin-top:8px">
            <div class="spinner-border text-primary" role="status" >
                <span class="sr-only">Updating...</span>
            </div>
            Updating...
        </div>
    </div>
</div>

<div class="row" *ngIf="channel!=null && channel.device!=null && channel.device.serial!=null">
    <div class="col-ld-8 col-md-6">
        <div class="card b-1 hover-shadow mt-2 mb-2">
            <div class="card-header">
                {{channel.device.serial}}
            </div>
            <div class="card-body">
                <div class="row">
                    <app-digitbutton *ngIf="channel.index===1" [serial]="channel.serial" [channelindex]=1 [title]='1'
                        [ledstatus]="channel.ledStatus" [mode]="channel.mode" class="col-3"></app-digitbutton>
                    <app-digitbutton *ngIf="channel.index!==1" [serial]="channel.serial" [channelindex]=1 [title]='1'
                        [ledstatus]=-1 [mode]="channel.mode" disabled class="col-3"></app-digitbutton>

                    <app-digitbutton *ngIf="channel.index===2" [serial]="channel.serial" [channelindex]=2 [title]='2'
                        [ledstatus]="channel.ledStatus" [mode]="channel.mode" class="col-3"></app-digitbutton>
                    <app-digitbutton *ngIf="channel.index!==2" [serial]="channel.serial" [channelindex]=2 [title]='2'
                        [ledstatus]=-1 [mode]="channel.mode" disabled class="col-3"></app-digitbutton>

                    <app-digitbutton *ngIf="channel.index==3" [serial]="channel.serial" [channelindex]=3 [title]='3'
                        [ledstatus]="channel.ledStatus" [mode]="channel.mode" class="col-3"></app-digitbutton>
                    <app-digitbutton *ngIf="channel.index!=3" [serial]="channel.serial" [channelindex]=3 [title]='3'
                        [ledstatus]=-1 [mode]="channel.mode" disabled class="col-3"></app-digitbutton>

                    <app-digitbutton *ngIf="channel.index==4" [serial]="channel.serial" [channelindex]=4 [title]='4'
                        [ledstatus]="channel.ledStatus" [mode]="channel.mode" class="col-3"></app-digitbutton>
                    <app-digitbutton *ngIf="channel.index!=4" [serial]="channel.serial" [channelindex]=4 [title]='4'
                        [ledstatus]=-1 [mode]="channel.mode" disabled class="col-3"></app-digitbutton>
                </div>
            </div>
            <div class="card-footer">

                <span><strong>STATUS: </strong>{{channel.ledText}}</span>

            </div>

        </div>
    </div>

</div>


<br />
<div class="row ">
    <div class="col-ld-8 col-md-6">
        <div class="card card-body bg-light">

            <span><strong>CODE: </strong>{{channel?.codeText}}</span>

        </div>
    </div>
</div>

<div class="row">
    <div class="col-ld-8 col-md-6 m-0 p-2">
        <div class="row">
            <div class="col-6">
                <button *ngIf="channel!=null && !channel.IsFilled" ngbTooltip="Generate password" placement="top"
                    (click)="sb.generateNewChannelCode(channel)" class="btn btn-md f-h-50 myButtonWizard"><i
                        class="fa fa-2x fa-magic "></i></button>

                <button ngbTooltip="Change password" placement="top" (click)="sb.editChannel(channel)"
                    class="btn btn-md f-h-50 myButtonChangePassword"><i class="fa fa-2x  fa-edit "></i></button>

               
            </div>

            <div class="col-6 text-right">
                <button ngbTooltip="Empty/Fill locker" (click)="sb.toggleFillStatus(channel)" class="btn btn-md f-h-50 myButtonFill ">
                    <i class="fa fa-2x fa-toggle-on"></i>
                </button>
                <button ngbTooltip="Open" (click)="sb.openChannel(channel)" class="btn btn-md f-h-50 myButtonOpen">
                    <i class="fa fa-2x fa-key"></i>
                </button>
            </div>
        </div>

    </div>
</div>