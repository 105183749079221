
export class ChannelDevice {
    id: string;
    serial: string;
    hwVersion: string;
    isOnline: boolean;
    swVersion: string;
    deviceType: string;
    endPoint: string;
}

export class Channel {
    id: string;
    index: number;
    alias: string;
    code: string;
    ledStatus: number;
    mode: number;
    device: ChannelDevice = new ChannelDevice();

    public get codeText(): string {
        if (this.code && this.code !== '0') {
            return  this.code;
        } else {
            return 'No Code!';
        }
    }

    public get modeText(): string {
        if (this.mode && this.mode > 0) {
            if (this.mode === 1) {
                return 'Auto generate codes';
            } else if (this.mode === 2) {
                return 'With preserve code';
            } else if (this.mode === 3) {
                return 'With code loss';
            }
        }
        return 'Not programmed!';
    }

    public get ledText(): string {
        if (this.ledStatus && this.ledStatus < 0) {
            return 'Not programmed!';
        } else if (this.ledStatus === 0) {
            return 'Has code and is filled';
        } else if (this.ledStatus === 1) {
            return 'Not in use';
            //this.buttonCss = this.buttonCss + ' black';
        } else if (this.ledStatus === 2) {
            return 'Has code but is empty';
            // this.buttonCss = this.buttonCss + ' blink';
        } else if (this.ledStatus === 3) {
            return 'Has code but filled from outside';
            // this.buttonCss = this.buttonCss + ' blinkfast';
        }
    }

    public get IsFilled(): boolean {
        return this.ledStatus === 0 || this.ledStatus === 3;
    }
}

