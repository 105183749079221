import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AccountService extends ApiService {

    constructor(httpClient: HttpClient) {
        super(httpClient, 'account', 'v1', 'account');
    }

}
