import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api.service';

@Injectable()
export class DeploymentplanService extends ApiService {

    constructor(httpClient: HttpClient) {
        super(httpClient, 'deploymentplan', 'v1', 'deploymentplan');
    }

}
