import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './views/home/home.component';
import { CommonModule } from '@angular/common';
import { AuthGuard } from './_guards/auth.guard';
import { Role } from './_models';
import { LoginComponent } from './views/login/login.component';
import { AccountComponent } from './views/account/account/account.component';
import { ChannellistComponent } from './views/channels/channellist/channellist.component';
import { EventComponent } from './views/event/event.component';
import { AdminmenuComponent } from './views/adminmenu/adminmenu.component';
import { LinkdeviceComponent } from './views/linkdevice/linkdevice.component';
import { RegisterComponent } from './views/register/register.component';
import { DevicelistComponent } from './views/channels/devicelist/devicelist.component';
import { ChanneldetailComponent } from './views/channels/channeldetail/channeldetail.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'account', component: AccountComponent, canActivate: [AuthGuard] },
  { path: 'events', component: EventComponent, canActivate: [AuthGuard] },
  { path: 'devices', component: ChannellistComponent, canActivate: [AuthGuard] },
  { path: 'devicelist', component: DevicelistComponent, canActivate: [AuthGuard] },
  { path: 'channeldetail', component: ChanneldetailComponent, canActivate: [AuthGuard] },
  { path: 'linkdevice', component: LinkdeviceComponent, canActivate: [AuthGuard] },
  { path: 'admin', component: AdminmenuComponent, canActivate: [AuthGuard] },
  { path: 'register', component: RegisterComponent },
  { path: 'login', component: LoginComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  // {
  //   path: 'admin', component: AdminComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin] }
  // },
  {
    path: 'login',
    component: LoginComponent
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { useHash: true })],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
