import { Injectable } from '@angular/core';
import { AuthenticationService } from '../api/services/authentication.service';
import { User, Role } from '../_models';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { map, first, delay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ChannelService } from '../api/services/channel.service';
import { Channel } from '../_models/channel';
import { ChannelRequest } from '../api';
import { DeviceCommandService } from '../api/services/devicecommand.service';
import { DeviceCommand } from '../_models/devicecommand';
import { LogOnWebClientRequest } from '../_models/logonwebclientrequestv1';
import { environment } from '../../environments/environment';
import { EventService } from '../api/services/event.service';
import { DeviceEvent } from '../_models/deviceevent';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ChanneleditComponent } from '../views/channels/channeledit/channeledit.component';
import { AccountService } from '../api/services/account.service';
import { AccountV1 } from '../_models/account';
import { LinkdeviceComponent } from '../views/linkdevice/linkdevice.component';
import { DeviceService } from '../api/services/device.service';
import { DeploymentplanService } from '../api/services/deploymentplan.service';
import { FirmwareService } from '../api/services/firmware.service';
import { DeploymentPlan, Pipeline, PipelineEnvironment } from '../_models/deploymentplan';
import { Firmware } from '../_models/firmware';
import { EditcustomereditviewComponent } from '../views/adminviews/editcustomereditview/editcustomereditview.component';
import { Device } from '../_models/device';
import { ApiResponse } from '../_models/apiresponse';


@Injectable()
export class AppSandbox {

    bsCurrentUser = new BehaviorSubject<User>(null);

    bsChannels = new BehaviorSubject<Channel[]>([]);
    bsEvents = new BehaviorSubject<DeviceEvent[]>([]);
    bsAccounts = new BehaviorSubject<AccountV1[]>([]);
    bsDeploymentplans = new BehaviorSubject<DeploymentPlan[]>([]);
    bsFirmwares = new BehaviorSubject<Firmware[]>([]);

    bsCurrentChannel = new BehaviorSubject<Channel>(null);
    public currentchannel$ = this.bsCurrentChannel.asObservable();

    // bsDevices = new BehaviorSubject<Device[]>([]);
    // public devices$ = this.bsDevices.asObservable();

    bsLastUpdate = new BehaviorSubject<Date>(null);
    bsReloadUpdate = new BehaviorSubject<Date>(null);

    public isReLoading = true;
    public lastupdate$ = this.bsLastUpdate.asObservable();
    public lastreloadupdate$ = this.bsReloadUpdate.asObservable();
    public currentUser$ = this.bsCurrentUser.asObservable();
    public isAdmin$ = this.currentUser$.pipe(
        map(t => t && t.role === Role.Admin)
    );
    public channels$ = this.bsChannels.asObservable();
    public devices$ = this.channels$.pipe(
        map(items => {
            const result: Device[] = [];

            items.forEach(ch => {
                let device = result.find(it => it.serial === ch.device.serial);
                if (device == null) {
                    device = new Device();
                    device.id = ch.device.id;
                    device.devicetype = ch.device.deviceType;
                    device.endPoint = ch.device.endPoint;
                    device.hwversion = ch.device.hwVersion;
                    device.isOnline = ch.device.isOnline;
                    device.serial = ch.device.serial;
                    device.server = ch.device.serial;
                    device.swversion = ch.device.swVersion;
                    result.push(device);
                }
                switch (ch.index) {
                    case 1:
                        device.ch1 = ch;
                        break;
                    case 2:
                        device.ch2 = ch;
                        break;
                    case 3:
                        device.ch3 = ch;
                        break;
                    case 4:
                        device.ch4 = ch;
                        break;
                }

                return result;
            });

            return result;
        })
    );
    public deviceevents$ = this.bsEvents.asObservable();
    public accounts$ = this.bsAccounts.asObservable();
    public deploymentplans$ = this.bsDeploymentplans.asObservable();
    public firmwares$ = this.bsFirmwares.asObservable();

    private currentAccount: AccountV1 = null;
    private editChannelDialogRef: MatDialogRef<ChanneleditComponent> = null;
    private addDeviceDialogRef: MatDialogRef<LinkdeviceComponent> = null;

    private editUserDialogRef: MatDialogRef<EditcustomereditviewComponent> = null;
    private registerwebclientRequest: LogOnWebClientRequest = null;

    constructor(
        private authenticationService: AuthenticationService,
        private channelService: ChannelService,
        private devicecommandService: DeviceCommandService,
        private accountService: AccountService,
        private eventService: EventService,
        private deviceService: DeviceService,
        private deploymentplanService: DeploymentplanService,
        private firmwareService: FirmwareService,
        private router: Router,
        public dialog: MatDialog
    ) {

        // on item change do a reload
        this.lastupdate$.pipe(delay(2000)).subscribe(el => this.reloadChannels());
        // In general, do a update each 3 minutes
        this.lastreloadupdate$.pipe(delay(60000)).subscribe(el => this.reloadChannels());

        this.authenticationService.currentUser
            .subscribe(x => {                
                this.bsCurrentUser.next(x);
                this.reloadChannels();
            });
    }

    public logout() {
        this.bsChannels.next([]);
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    }

    public showChannel(serial: string, channelIndex: number) {
        let found = false;
        this.bsChannels.getValue().forEach(el => {
            if (el.device.serial === serial && el.index === channelIndex) {
                this.bsCurrentChannel.next(el);
                found = true;
            }
        });
        if (found) {
            this.router.navigate(['/channeldetail']);
        }
    }

    public reloadAccounts() {
        this.accountService
            .get<AccountV1[]>().pipe(
                first(), // only subscribe once
                map(data => {
                    const lst = [] as AccountV1[];
                    data.forEach(el => {
                        const newobj = new AccountV1();
                        newobj.id = el.id;
                        newobj.companyname = el.companyname;
                        newobj.email = el.email;
                        newobj.firstname = el.firstname;
                        newobj.lastname = el.lastname;
                        newobj.username = el.username;
                        newobj.website = el.website;
                        newobj.isadmin = el.isadmin;                        
                        newobj.photourl = el.photourl;
                        newobj.isemailverified = el.isemailverified;
                        lst.push(newobj);
                    });
                    return lst;
                })
            ).subscribe(data => {
                this.bsAccounts.next(data);
            });
    }

    public reloadDeviceEvents() {
        this.eventService
            .get<DeviceEvent[]>().pipe(
                first(), // only subscribe once
                map(data => {
                    const lst = [] as DeviceEvent[];
                    data.forEach(el => {
                        const newobj = new DeviceEvent();
                        newobj.date = el.date;
                        newobj.channel = el.channel;
                        newobj.message = el.message;
                        lst.push(newobj);
                    });
                    return lst;
                })
            ).subscribe(data => {
                this.bsEvents.next(data);
            });
    }

    public reloadDeploymentplans() {
        this.deploymentplanService
            .get<DeploymentPlan[]>().pipe(
                first(), // only subscribe once
                map(data => {
                    const lst = [] as DeploymentPlan[];
                    data.forEach(el => {
                        const newobj = new DeploymentPlan();
                        newobj.id = el.id;
                        newobj.name = el.name;

                        el.pipelines.forEach(p => {
                            const pipeline = new Pipeline();
                            pipeline.id = p.id;
                            pipeline.name = p.name;

                            p.environments.forEach((e: any) => {
                                const env = new PipelineEnvironment();
                                env.id = e.id;
                                env.currentFirmwareVersionDate = e.currentFirmwareVersionDate;
                                env.currentFirmwareVersionId = e.currentFirmwareVersionId;
                                env.deploymentplanid = e.deploymentPlanId;
                                env.name = e.name;
                                env.pipelineid = e.pipelineId;
                                env.sequence = e.sequence;
                                env.swversion = e.swVersion;
                                env.swversionname = e.swVersionName;
                                env.created = e.created;
                                env.checksum = e.checksum;

                                pipeline.environments.push(env);
                            });
                            newobj.pipelines.push(pipeline);
                        });

                        lst.push(newobj);
                    });
                    return lst;
                })
            ).subscribe(data => {
                this.bsDeploymentplans.next(data);
            });
    }

    public stopEditingCustomer() {
        if (this.editUserDialogRef !== null) {
            this.editUserDialogRef.close(null);
        }
    }

    public editCustomer(customer: AccountV1) {
        this.currentAccount = customer;
        this.stopEditingCustomer();
        this.editUserDialogRef = this.dialog.open(EditcustomereditviewComponent, {
            data: {
                customer: customer
            }
        });

        this.editUserDialogRef.afterClosed().subscribe(result => {
            if (result !== null) {
                this.currentAccount.companyname = result.controls.companyname.value;
                this.currentAccount.email = result.controls.email.value;
                this.currentAccount.firstname = result.controls.firstname.value;
                this.currentAccount.isadmin = result.controls.isadmin.value;
                this.currentAccount.lastname = result.controls.lastname.value;                
                this.currentAccount.photourl = result.controls.photourl.value;
                this.currentAccount.username = result.controls.username.value;
                this.currentAccount.website = result.controls.website.value;

                this.accountService.put(null, this.currentAccount).subscribe(value => {
                    this.reloadAccounts();

                    if (value === true) {
                        alert('Saved!');
                    }
                }, err => {
                    alert(err);
                });
            }
        });

    }

    public linkNewDevice() {
        this.stopLinkingNewDevice();

        this.addDeviceDialogRef = this.dialog.open(LinkdeviceComponent);

        this.addDeviceDialogRef.afterClosed().subscribe(result => {
            if (result !== null) {
                const data = {
                    serial: result.controls.serial.value,
                    code1: result.controls.code1.value,
                    code2: result.controls.code2.value,
                    code3: result.controls.code3.value,
                    code4: result.controls.code4.value
                };

                this.deviceService.post(data).subscribe(value => {
                    this.reloadChannels();

                    if (value === true) {
                        alert('Device added!');
                    }
                }, err => {
                    alert(err);
                });
            }
        });

    }

    public stopLinkingNewDevice() {
        if (this.addDeviceDialogRef !== null) {
            this.addDeviceDialogRef.close(null);
        }
    }

    public editChannel(ch: Channel) {
        this.stopEditingChannel();
        if (ch !== null) {
            this.editChannelDialogRef = this.dialog.open(ChanneleditComponent, {
                data: {
                    channel: ch,
                    code: this.generateNewRandomCode()
                }
            });

            this.editChannelDialogRef.afterClosed().subscribe(result => {
                if (result !== null) {
                    const code: string = result.controls.newpassword.value;
                    const keepcode: boolean = result.controls.keepcode.value;

                    if (ch.device.isOnline) {
                        const cmd = new DeviceCommand();
                        cmd.index = ch.index;
                        cmd.serial = ch.device.serial;
                        cmd.command = 'RequestSet';
                        cmd.mode = keepcode ? 2 : 3;
                        cmd.code = code;
                        // optimistic update, will be reset later if fails
                        ch.code = cmd.code;
                        ch.mode = cmd.mode;

                        this.devicecommandService.post<ApiResponse>(cmd).subscribe(response => {
                            if (!response.success && response.errormessage!=''){
                                alert(response.errormessage);
                            }
                            this.isReLoading = true;
                            this.bsLastUpdate.next(new Date()); // will reload channels
                        }, error => {
                            alert(error);
                        });
                    } else {
                        alert('Sorry, device is currently offline!');
                    }
                }
            });
        }
    }

    private stopEditingChannel() {
        if (this.editChannelDialogRef !== null) {
            this.editChannelDialogRef.close(null);
        }
    }

    public toggleFillStatus(ch:Channel){
        if (ch.device.isOnline) {
            const cmd = new DeviceCommand();
            cmd.index = ch.index;
            cmd.serial = ch.device.serial;
            cmd.command = 'RequestSetFillState';
            cmd.mode = 0;

            this.devicecommandService.post<ApiResponse>(cmd).subscribe(response => {               
                if (!response.success && response.errormessage!=''){
                    alert(response.errormessage);
                }
                this.isReLoading = true;
                this.bsLastUpdate.next(new Date()); // will reload channels
            }, error => {
                alert(error);
            });
        } else {
            alert('Sorry, device is currently offline!');
        }
    }

    public openChannel(ch: Channel) {
        if (ch.device.isOnline) {
            const cmd = new DeviceCommand();
            cmd.index = ch.index;
            cmd.serial = ch.device.serial;
            cmd.command = 'RequestOpen';
            cmd.mode = 0;

            this.devicecommandService.post<ApiResponse>(cmd).subscribe(response => {               
                if (!response.success && response.errormessage!=''){
                    alert(response.errormessage);
                }
            }, error => {
                alert(error);
            });
        } else {
            alert('Sorry, device is currently offline!');
        }
    }

    public generateNewRandomCode(): string {
        let newpwd = '';
        for (let i = 0; i < 6; i++) {
            newpwd = newpwd + this.randomInt(1, 4).toString();
        }
        return newpwd;
    }

    public generateNewChannelCode(ch: Channel) {
        if (ch.device.isOnline) {
            const cmd = new DeviceCommand();
            cmd.index = ch.index;
            cmd.serial = ch.device.serial;
            cmd.command = 'RequestSet';
            cmd.mode = 3;
            cmd.code = this.generateNewRandomCode();

            this.devicecommandService.post<ApiResponse>(cmd).subscribe(response => {               
                if (!response.success && response.errormessage!=''){
                    alert(response.errormessage);
                }
                this.isReLoading = true;
                this.bsLastUpdate.next(new Date()); // will reload channels
            }, error => {
                alert(error);
            });
        } else {
            alert('Sorry, device is currently offline!');
        }
    }



    private randomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    public reloadChannels() {

        const usr = this.bsCurrentUser.getValue();

        if (usr && usr.id && usr.id !== '') {
            // this is a valid user!

            this.channelService
                .get<Channel[]>({ filter: '' } as ChannelRequest).pipe(
                    first(), // only subscribe onece                  
                    map(data => {
                        const lst = [] as Channel[];
                        data.forEach(el => {                            
                            const newobj = new Channel();
                            newobj.alias = el.alias;
                            newobj.code = el.code;
                            newobj.device = el.device;
                            newobj.id = el.id;
                            newobj.index = el.index;
                            newobj.ledStatus = el.ledStatus;
                            newobj.mode = el.mode;
                            lst.push(newobj);
                        });
                        return lst;
                    })
                ).subscribe(data => {
                    this.bsChannels.next(data);

                    const curChannel = this.bsCurrentChannel.value;
                    if (curChannel) {
                        data.forEach(el => {
                            if (el.id === curChannel.id) {
                                this.bsCurrentChannel.next(el);
                            }
                        });
                    }

                    this.bsReloadUpdate.next(new Date());
                    this.isReLoading = false;
                });


        } else {
            this.bsChannels.next([]);
        }
    }



}
