import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { AppSandbox } from '../../sandboxes/app.sandbox';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public currentUser$ = this.sb.currentUser$;
  public isAdmin$ = this.sb.isAdmin$;

  @Output() public sidenavToggle = new EventEmitter();

  constructor(public sb: AppSandbox) {

  }

  ngOnInit() {
  }

  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  }
}
