<mat-card>
    <mat-card-title>Customers</mat-card-title>
    <mat-card-content>

        <div fxLayout fxLayoutAlign="center center">
            <mat-form-field fxFlex="40%">
                <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Filter">
            </mat-form-field>
        </div>
        <table *ngIf="dataSource!==null" mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="firstname">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> First Name </th>
                <td mat-cell *matCellDef="let element"> {{element.firstname}} </td>
            </ng-container>


            <ng-container matColumnDef="lastname">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </th>
                <td mat-cell *matCellDef="let element"> {{element.lastname}} </td>
            </ng-container>

            <ng-container matColumnDef="companyname">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Company </th>
                <td mat-cell *matCellDef="let element"> {{element.companyname}} </td>
            </ng-container>

            <ng-container matColumnDef="username">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Username </th>
                <td mat-cell *matCellDef="let element"> {{element.username}} </td>
            </ng-container>

            <ng-container matColumnDef="isadmin">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Admin? </th>
                <td mat-cell *matCellDef="let element"> {{element.isadmin}} </td>
            </ng-container>

            <ng-container matColumnDef="details">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element" class="text-right">
                    <!-- <button mat-icon-button color="primary" (click)="redirectToDetails(element.id)">
                        <mat-icon class="mat-18">reorder</mat-icon>
                    </button> -->
                    <button mat-icon-button color="accent" (click)="edit(element)">
                        <mat-icon class="mat-18">edit</mat-icon>
                    </button>
                    <!-- <button mat-icon-button color="warn" (click)="redirectToDelete(element.id)">
                        <mat-icon class="mat-18">delete</mat-icon>
                    </button> -->
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <footer>

            </footer>
        </table>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    </mat-card-content>
    <mat-card-actions class="m-t-0">
        <button mat-icon-button routerLink="/register" type="button" color="primary">
            <mat-icon class="mat-18">note_add</mat-icon>Add
        </button>
    </mat-card-actions>
</mat-card>