import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api.service';

@Injectable()
export class FirmwareService extends ApiService {

    constructor(httpClient: HttpClient) {
        super(httpClient, 'firmware', 'v1', 'firmware');
    }

}
