import { Component, OnInit } from '@angular/core';
import { AppSandbox } from '../../../sandboxes/app.sandbox';


@Component({
  selector: 'app-devicelist',
  templateUrl: './devicelist.component.html',
  styleUrls: ['./devicelist.component.scss']
})
export class DevicelistComponent implements OnInit {

  public devices$ = this.sb.devices$;

  constructor(public sb: AppSandbox) { }

  ngOnInit() {
  }

}
