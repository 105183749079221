import { identifierModuleUrl } from '@angular/compiler';

export class DeploymentPlan {
    id: string;
    name: string;
    pipelines: Pipeline[] = [];
}

export class Pipeline {
    id: string;
    name: string;
    environments: PipelineEnvironment[] = [];
}

export class PipelineEnvironment {
    id: string;
    name: string;
    sequence: number;
    pipelineid: string;
    deploymentplanid: string;
    currentFirmwareVersionId: string;
    currentFirmwareVersionDate: string;

    swversion: string;
    swversionname: string;
    created: Date;
    checksum: string;
}
