<mat-card>
    <mat-card-title>Change password</mat-card-title>
    <mat-card-content>
           <form [formGroup]="channelForm" (ngSubmit)="onSubmit()">
            <mat-form-field>
                <input type="text" matInput placeholder="New password" autocomplete="off" formControlName="newpassword">
            </mat-form-field>

            <mat-checkbox matInput formControlName="keepcode" labelPosition="after">Keep Code</mat-checkbox>
           
            <div class="button">
                 <button type="submit" mat-button>Save</button>
                 <button type="button" (click)="closeDialog()" mat-button>Cancel</button>
            </div>
        </form>
    </mat-card-content>
</mat-card>