import { Channel } from './channel';

export class Device {
    public id: string;
    public serial: string;
    public devicetype: string;
    public endPoint: string;
    public hwversion: string;
    public isOnline: boolean;
    public swversion: string;
    public server: string;

    public ch1: Channel = new Channel();
    public ch2: Channel = new Channel();
    public ch3: Channel = new Channel();
    public ch4: Channel = new Channel();
}