<mat-card>
    <mat-card-title>Link a new device</mat-card-title>
    <mat-card-subtitle>It's easy to add a new device!</mat-card-subtitle>
    <mat-card-content>
        <p>Go to you take-it and program the 4 channels with a different code.<br/> 
            When this is done, fill in the form below:</p>
        <form [formGroup]="channelForm" (ngSubmit)="onSubmit()">
            <mat-form-field>
                <input type="text" matInput placeholder="Device Serial" autocomplete="off" formControlName="serial">
            </mat-form-field><br />
            <mat-form-field>
                <input type="text" matInput placeholder="Code1" autocomplete="off" formControlName="code1">
            </mat-form-field><br />
            <mat-form-field>
                <input type="text" matInput placeholder="Code2" autocomplete="off" formControlName="code2">
            </mat-form-field><br />
            <mat-form-field>
                <input type="text" matInput placeholder="Code3" autocomplete="off" formControlName="code3">
            </mat-form-field><br />
            <mat-form-field>
                <input type="text" matInput placeholder="Code4" autocomplete="off" formControlName="code4">
            </mat-form-field><br />

            <div class="button">
                <button type="submit" mat-button>Save</button>
                <a class="button" (click)="close()">Cancel</a>
            </div>
        </form>
    </mat-card-content>
</mat-card>