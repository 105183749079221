<div class="col-md-12">

    <button ngbTooltip="Refresh" placement="top" (click)="sb.reloadChannels()" class="btn btn-md m-1"><i
            class="fa fa-2x fa-refresh"></i>
    </button>

    <button ngbTooltip="Add Device" placement="top" (click)="sb.linkNewDevice()" class="btn btn-md m-1"><i
        class="fa fa-2x fa-plus"></i>
</button>
</div>
<div class="col-md-12">
    <div *ngFor="let it of (channels$|async)" class="card b-1 hover-shadow mb-20">

        <div class="media card-body">
            <div class="media-left pr-12">
                <img class="avatar avatar-xl no-radius" src="../../../../assets/images/locker.png" alt="...">
            </div>
            <div class="media-body">
                <div class="mb-2">
                    <span class="fs-20 pr-16">Channel: {{it.alias}}</span>
                </div>
                <small class="fs-16 fw-300 ls-1"> {{it.codeText}} {{it.modeText}}</small>
                <div *ngIf="it.ledText">
                    <strong>Status:</strong>
                    <span> {{it.ledText}}</span>
                </div>
            </div>
            <div class="media-right text-right d-none d-md-block">
                <p class="fs-14 text-fade mb-12"><i class="fa fa-map-marker pr-1"></i>{{it.device.deviceType}} </p>
                <span class="text-fade"><i class="fa fa-money pr-1"></i> {{it.device.serial}}</span>
                <p class="text-danger" *ngIf="it.device.isOnline===false">Offline</p>
            </div>

        </div>
        <footer class="card-footer flexbox align-items-center" *ngIf="it.device.isOnline===true">

            <div class="card-hover-show ">
                <div class="row">
                    <button ngbTooltip="Open" placement="top" (click)="sb.openChannel(it)" class="btn btn-md m-1"><i
                            class="fa fa-key"></i></button>
                    <!-- <button ngbTooltip="Reset" placement="top" (click)="sb.openChannel(it)" class="btn btn-md"><i
                            class="fa fa-recycle"></i></button> -->
                    <button ngbTooltip="Change password" placement="top" (click)="sb.editChannel(it)"
                        class="btn btn-md "><i class="fa fa-edit"></i></button>
                    <button *ngIf="!it.IsFilled" ngbTooltip="Generate password" placement="top"
                        (click)="sb.generateNewChannelCode(it)" class="btn btn-md "><i class="fa fa-magic"></i></button>
                </div>
            </div>
        </footer>
    </div>
</div>