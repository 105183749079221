import { Component, OnInit } from '@angular/core';
import { AppSandbox } from '../../sandboxes/app.sandbox';


@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent implements OnInit {

  public events$ = this.sb.deviceevents$;

  constructor(public sb: AppSandbox) {

  }

  ngOnInit() {
    this.sb.reloadDeviceEvents();
  }

}
