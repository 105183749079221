import { ApiService } from '../api.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

@Injectable()
export class ChannelService extends ApiService {

    constructor(httpClient: HttpClient) {
        super(httpClient, 'channel', 'v1', 'channel');
    }

}
