<!-- <div class="Grid-item">

        <div class="Card Card--radius">
            <div class="Card-thumb Card-thumb--portrait pa4" style="background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/210284/flex-1.jpg);">
                <div class="Avatar Avatar--square Avatar--m" style="background: #4A8CFF;">
                    <div class="Avatar--initials">SS</div>
                    <div class="Avatar--img Avatar--square"></div>
                </div>
                <div class="Card-label">
                    <span class="Label-icon Label-icon--right Label--round Label--success">SEIS<i class="material-icons md-18">check</i></span>
                </div>
                <div class="Progress">
                    <div class="Progress-bar Progress-bar--success Tooltip" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style="width:40%">
                        <span class="Tooltip-text Tooltip-text--top Tooltip-text--success">70% Raised</span>
                    </div>
                </div>
            </div>
            <article class="pa4">
                <h3 class="f5"><a class="Link Link--underline-hover color-grey-80" href="#">Fusion Complex</a></h3>
                <p class="Card-description mt2">Comprising of three, three bedroom apartments and three two bedroom apartments this secure, modern development has the design team's signature architectural finish, attention to detail.</p>
                <div class="Card-grid Card-grid--2 mt3">
                    <div class="Card-grid-item">
                        <span class="f4"><strong>£250,000</strong></span>
                        <p class="f2 color-grey-60">Raised</p>
                    </div>
                    <div class="Card-grid-item">
                      <span class="f4"><strong>£5,000,000</strong></span>
                      <p class="f2 color-grey-60">Needed</p>
                    </div>
                </div>
                <div class="Card-location mt3">
                    <i class="material-icons md-18">location_on</i>
                    <p class="ml4">London, UK</p>
                </div>
                <div class="mt3">
                    <a href="#" class="Button Button--primary Button--s mr1">Find out more</a>
                    <a href="#" class="Icon Icon--s Button Button--secondary Button--icon Tooltip mr1">
                        <i class="material-icons md-18">star</i>
                        <span class="Tooltip-text Tooltip-text--top Tooltip-text--default">Shortlist</span>
                    </a>
                    <a href="#" class="Icon Icon--s Button Button--secondary Button--icon Tooltip">
                        <i class="material-icons md-18">delete</i>
                        <span class="Tooltip-text Tooltip-text--top Tooltip-text--default">Delete</span>
                    </a>
                </div>
            </article>
        </div>
    </div> -->

<div class="Grid-item">
    <div class="Card">
        <div>
            <div class="Flex-row">
                <div class="Flex-column Flex--15">
                    <div class="Card-thumb pa4"
                        style="background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/210284/flex-1.jpg);">
                        <div class="Card-avatar">
                            <div class="Avatar Avatar--square Avatar--m" style="background: #4A8CFF;">
                                <div class="Avatar--initials">SS</div>
                                <div class="Avatar--img Avatar--square"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="Flex-column Flex--25 ma4">
                    <h3 class="f5"><a class="Link Link--underline-hover color-grey-80" href="#">Fusion Complex</a></h3>
                    <div class="Card-location mt3">
                        <i class="material-icons md-18">location_on</i>
                        <p class="ml4">449-453 West Derby Road, Liverpool L6 4BL, United Kingdom</p>
                    </div>
                    <p class="mt2"><strong>Developer</strong></p>
                    <p class="color-grey-60">Evergreen Developments</p>
                </div>

                <div class="Flex-column ma4">
                    <div class="Select Select--default Select--m">
                        <select>
                            <option value="">Messaging</option>
                            <option value="">Documents Requested</option>
                            <option value="">Reviewing Documents</option>
                            <option value="">Meeting</option>
                            <option value="">Due Diligence</option>
                            <option value="">Contracts</option>
                            <option value="">Offer Made</option>
                            <option value="">Invested</option>
                            <option value="">No Longer Interested</option>
                        </select>
                    </div>
                    <div class="Card-text-links mt3">
                        <a class="Link Link--underline-hover color-brand" href="#">View Full Listing</a>
                        <a class="Link Link--underline-hover color-brand" href="#">View Message History</a>
                        <a class="Link Link--underline-hover color-brand" href="#">Contact Developer</a>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>