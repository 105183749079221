import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthenticationService } from './services/authentication.service';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { ChannelService } from './services';
import { DeviceCommandService } from './services/devicecommand.service';
import { EventService } from './services/event.service';
import { AccountService } from './services/account.service';
import { DeviceService } from './services/device.service';
import { UserService } from './services/user.service';
import { DeploymentplanService } from './services/deploymentplan.service';
import { FirmwareService } from './services/firmware.service';

@NgModule({

})
export class ApiModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ApiModule,
      providers: [
        AuthenticationService,
        ChannelService,
        DeviceCommandService,
        EventService,
        AccountService,
        DeviceService,        
        UserService,
        DeploymentplanService,
        FirmwareService
      ]
    };
  }
}
