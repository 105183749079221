import { HttpClient, HttpResponse } from '@angular/common/http';
import * as qs from 'qs';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap, take, withLatestFrom } from 'rxjs/operators';

export abstract class ApiService {

  protected baseUrl;
  private cache$ = new BehaviorSubject<{ url: string, cache: any, expiryTime: Date }[]>([]);

  constructor(
    private http: HttpClient,
    private apiDomain: string,
    private apiVersion: string,
    private entity: string) {
    this.baseUrl = environment.baseurl + '/api/v1/' + apiDomain;
  }

  public get<TModel>(filter: any = null): Observable<TModel> {
    return this.getByUrl<TModel>(`${this.baseUrl}${this.getQueryString(filter)}`);
  }

  public getById<TModel>(id: number): Observable<TModel> {
    return this.getByUrl<TModel>(`${this.baseUrl}/${id}`);
  }

  public post<TModel>(item?: any, url?: string): Observable<TModel> {
    return this.http.post<TModel>(url ? url : this.baseUrl, item);
  }

  public delete(url: string): Observable<any> {
    return this.http.delete(url);
  }

  public put(url: string, item?: any): Observable<any> {
    return this.http.put(url ? url : this.baseUrl, item);
  }

  protected getByUrl<TModel>(url: string): Observable<TModel> {
    return this.cache$.pipe(
      take(1),
      map(cache => cache.find(c => c.url === url)),
      switchMap(cache => {
        if (cache && cache.expiryTime > new Date()) {
          return of(cache.cache);
        }

        return this.http.get(url, { observe: 'response' })
          .pipe(
            withLatestFrom(this.cache$),
            map(([response, currentCache]) => {
              const maxAge = this.getMaxAgeFromHeader(response);
              if (maxAge) {
                const expiryTime = new Date();
                expiryTime.setSeconds(+expiryTime.getSeconds() + maxAge);

                this.cache$.next([...currentCache.filter(x => x.url !== url), {
                  url: url,
                  cache: response.body,
                  expiryTime: expiryTime
                }]);
              }
              return response.body;
            })
          );
      })
    );
  }

  private getMaxAgeFromHeader<T>(response: HttpResponse<T>): number {
    if (response.headers.has('Cache-Control')) {
      const cacheControlHeader = response.headers.get('Cache-Control');
      const cacheControlSubHeaders = cacheControlHeader.split(',');
      if (cacheControlSubHeaders) {
        cacheControlSubHeaders.forEach(cacheControlSubHeader => {
          const headerKeyAndValue = cacheControlSubHeader.split('=');
          if (headerKeyAndValue[0] === 'max-age') {
            return +headerKeyAndValue[1];
          }
        });
      }
    }
    return null;
  }

  protected getQueryString(queryModel: any) {
    const query = qs.stringify(queryModel);
    return query ? '?' + query : '';
  }
}

