import { Component, OnInit } from '@angular/core';
import { AppSandbox } from '../../../sandboxes/app.sandbox';
import { Channel } from '../../../_models/channel';


@Component({
  selector: 'app-channellist',
  templateUrl: './channellist.component.html',
  styleUrls: ['./channellist.component.scss']
})
export class ChannellistComponent implements OnInit {

  public channels$ = this.sb.channels$;

  constructor(public sb: AppSandbox) {

  }

  ngOnInit() {
  }

  public generate(ch: Channel) {
    if (ch.device.isOnline) {
      //   debugger;
      //   let newpwd = '';
      //   for (let i = 0; i < 6; i++) {
      //     newpwd = newpwd + this.randomInt(1, 4).toString();
      //   }
      //   const cmd = new DeviceCommand();
      //   cmd.serial = this.device.serial;
      //   cmd.data = '{ "cmd": "RequestSet", "serial":"' + this.device.serial + '", "index": ' + this.data.index + ', "mode": 3, "code":"' + newpwd + '" }';

      //   this.sb.addDeviceCommand(cmd);

    } else {
      alert('Sorry, device is currently offline!');
    }
  }


}
