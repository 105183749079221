import { Component, OnInit, ElementRef, ViewChild, Input, Output, Inject } from '@angular/core';
import { ControlValueAccessor, FormGroup, FormBuilder, Validators } from '@angular/forms';

import { EventEmitter } from 'events';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { Channel } from '../../../_models/channel';


@Component({
  selector: 'app-channeledit',
  templateUrl: './channeledit.component.html',
  styleUrls: ['./channeledit.component.scss']
})
export class ChanneleditComponent implements OnInit {

  channelForm: FormGroup;
  private channel: Channel = null;

  // convenience getter for easy access to form fields
  get f() { return this.channelForm.controls; }

  constructor(private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ChanneleditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
  ngOnInit() {
    this.channel = this.data.channel;

    this.channelForm = this.formBuilder.group({
      newpassword: [this.data.code, Validators.required],
      keepcode: [false, Validators.required]
    });
  }

  onSubmit() {
    // stop here if form is invalid
    if (this.channelForm.invalid) {
      return;
    }

    this.dialogRef.close(this.channelForm);
  }

  closeDialog() {
    this.dialogRef.close(null);
  }

}
