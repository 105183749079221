<mat-toolbar *ngIf="currentUser$|async" color="primary">
    <div fxHide.gt-xs>
        <button mat-icon-button (click)="onToggleSidenav()">
            <mat-icon>menu</mat-icon>
        </button>
    </div>
    <div>
        <a routerLink="/home">Menu</a>
    </div>
    <div fxFlex fxLayout="row" fxLayoutAlign="end" fxHide.xs>
        <ul fxLayout="row" fxLayoutGap="15px" class="navigation-items">
            <li *ngIf="isAdmin$|async">
                <a routerLink="/admin">Admin</a>
            </li>         
            <li>
                <a routerLink="/account">Account</a>
            </li>          
            <li>
                <a routerLink="/devicelist">Devices</a>
            </li>
            <li>
                <a routerLink="/events">Events</a>
            </li>
            <li>
                <a (click)="sb.logout()">Logout</a>
            </li>

        </ul>
    </div>
</mat-toolbar>