import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-channellistitem',
  templateUrl: './channellistitem.component.html',
  styleUrls: ['./channellistitem.component.scss']
})
export class ChannellistitemComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
